(function ($) {
	'use strict';
	function init() {
		let $featuredReleasesCarousel = $('.featured-releases-carousel');
		$featuredReleasesCarousel.slick({
			centerMode: true,
			centerPadding: '40px',
			dots: true,
			slidesToShow: 1,
			arrows: false,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 2000,
			variableWidth: true,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 1,
						arrows: false
					}
    		},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
						arrows: false
					}
    		},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						arrows: false
					}
    		}
  		]
		});
	}

	// wait fo the block to render before loading the script in the admin panel
	if (window.acf) {
		window.acf.addAction('render_block_preview/type=instax-product-featured-releases-carousel', function () {
			init();
		});
	} else {
		init();
	}
}(jQuery))
